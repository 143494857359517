import React from 'react';
import Logo from '../Assets/Images/SotoCheckIconWhite.png';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const CustomRow = styled(Row)`
  color: #ffffff;
  margin-top: 100px;
  font-size: 30px;

  .copy {
    font-size: 14px;
    margin-top: 50px;
    opacity: 0.5;
  }

  .light {
    font-weight: 300;
    font-size: 26px;
  }
`;

const Image = styled.img`
  flex: 1;
  width: 70%;
`;

const TextHeadline = styled.div`
  flex: 1;
  font-size: 6rem;
  font-weight: bolder;
  color: #ffffff;
`;

const TextPowered = styled.div`
  flex: 1;
  font-size: 1.5rem;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.5;
`;

/**
 * Dashboard()
 * @constructor
 */
export default function Dashboard() {
  return (
    <Container>
      <Row>
        <Col lg={3}></Col>
        <Col lg={6}>
          <Row>
            <Col>
              <Image src={Logo}></Image>
            </Col>
          </Row>
          <Row>
            <TextHeadline>SotoCheck</TextHeadline>
          </Row>
          <Row>
            <TextPowered>Powered by SotoSoft</TextPowered>
          </Row>

          <CustomRow>
            <div>
              <strong>Sotosoft S.L</strong>
            </div>
            <div className={'light'}>La Reserva S/N, Urb. Senda Chica Bloque 7 P1B</div>
            <div className={'light'}>11310 Sotogrande, Spanien</div>
            <br />
            <div className={'light'}>Mail: info@sotosoft.app</div>
            <div className={'light'}>Web: https://www.sotosoft.app</div>

            <div className={'copy'}>Copyright 2023 by Sotosoft S.L.</div>
          </CustomRow>

          <Row>
            <div style={{ marginTop: 30 }}>
              <Button variant="outline-light" size={'sm'} href={'/security'}>
                Datenschutz
              </Button>
            </div>
          </Row>
        </Col>
        <Col lg={3}></Col>
      </Row>
    </Container>
  );
}
