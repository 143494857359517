import React from 'react';
import './App.css';
import AppRouter from './Router/AppRouter';
import { ThemeProvider } from 'styled-components';
import LightTheme from './Library/Theme/LightTheme';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <ThemeProvider theme={LightTheme}>
      <AppRouter />
    </ThemeProvider>
  );
}

export default App;
