import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const InnerContainer = styled.div`
  padding: 50px 30px;
  display: block;
  color: #ffffff;
  text-align: left;
  font-size: 18px;

  div {
    padding: 0 0 30px 20px;
    text-align: justify;
  }
`;

const H1 = styled.h1`
  padding: 15px;
  background-color: ${(props) => props.theme.color.primaryColorLight};
  border-radius: 8px;
  text-align: center;
`;

const H2 = styled.h2`
  display: inline-block;
  padding: 15px;
  background-color: ${(props) => props.theme.color.primaryColorLight};
  border-radius: 8px;
`;

const H3 = styled.h3`
  display: inline-block;
  padding: 15px;
  background-color: ${(props) => props.theme.color.primaryColorLight};
  border-radius: 8px;
`;

const SubContainer = styled.div`
  padding-left: 50px;
`;

/**
 * Security()
 * @constructor
 */
export default function Security() {
  return (
    <Container fluid={'xl'}>
      <Row>
        <Col lg={12}>
          <InnerContainer>
            <>
              <H1 style={{ marginBottom: 80 }}>SotoSoft S.L.</H1>
              <div style={{ textAlign: 'right' }}>Stand 01.11.2023</div>

              <H2>DATENSCHUTZERKLÄRUNG</H2>

              <div>
                Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders hohen
                Stellenwert für die Geschäftsleitung der Sotosoft S.L. Eine Nutzung der Internetseiten der Sotosoft S.L.
                ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich. Sofern eine betroffene Person
                besondere Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchte, könnte
                jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die Verarbeitung
                personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche
                Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein.
              </div>
              <div>
                Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift, E-Mail-Adresse oder
                Telefonnummer einer betroffenen Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung
                und in Übereinstimmung mit den für die Sotosoft S.L. geltenden landesspezifischen
                Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit
                über Art, Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten
                informieren. Ferner werden betroffene Personen mittels dieser Datenschutzerklärung über die ihnen
                zustehenden Rechte aufgeklärt.
              </div>
              <div>
                Die Sotosoft S.L. hat als für die Verarbeitung Verantwortlicher zahlreiche technische und
                organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese Internetseite
                verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können Internetbasierte
                Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht
                gewährleistet werden kann. Aus diesem Grund steht es jeder betroffenen Person frei, personenbezogene
                Daten auch auf alternativen Wegen, beispielsweise telefonisch, an uns zu übermitteln.
              </div>

              <H2>1. BEGRIFFSBESTIMMUNGEN</H2>

              <div>
                Die Datenschutzerklärung der Sotosoft S.L. beruht auf den Begrifflichkeiten, die durch den Europäischen
                Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DS-GVO) verwendet wurden.
                Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere Kunden und
                Geschäftspartner einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die
                verwendeten Begrifflichkeiten erläutern.
              </div>
              <div>Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:</div>

              <SubContainer>
                <H3>A) PERSONENBEZOGENE DATEN</H3>
                <div>
                  Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
                  natürliche Person (im Folgenden „betroffene Person“) beziehen. Als identifizierbar wird eine
                  natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung
                  wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder
                  mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen,
                  wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert
                  werden kann.
                </div>

                <H3>B) BETROFFENE PERSON</H3>
                <div>
                  Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren
                  personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
                </div>

                <H3>C) VERARBEITUNG</H3>
                <div>
                  Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
                  solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die
                  Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen,
                  die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
                  Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die
                  Vernichtung.
                </div>

                <H3>D) EINSCHRÄNKUNG DER VERARBEITUNG</H3>
                <div>
                  Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel,
                  ihre künftige Verarbeitung einzuschränken.
                </div>

                <H3>E) PROFILING</H3>
                <div>
                  Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht,
                  dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf
                  eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung,
                  wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten,
                  Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.
                </div>

                <H3>F) PSEUDONYMISIERUNG</H3>
                <div>
                  Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die
                  personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen
                  betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert
                  aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass
                  die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person
                  zugewiesen werden.
                </div>

                <H3>G) VERANTWORTLICHER ODER FÜR DIE VERARBEITUNG VERANTWORTLICHER</H3>
                <div>
                  Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische
                  Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke
                  und Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser
                  Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der
                  Verantwortliche beziehungsweise können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht
                  oder dem Recht der Mitgliedstaaten vorgesehen werden.
                </div>

                <H3>H) AUFTRAGSVERARBEITER</H3>
                <div>
                  Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
                  Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
                </div>

                <H3>I) EMPFÄNGER</H3>
                <div>
                  Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der
                  personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten
                  handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem
                  Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten
                  jedoch nicht als Empfänger.
                </div>

                <H3>J) DRITTER</H3>
                <div>
                  Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der
                  betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der
                  unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die
                  personenbezogenen Daten zu verarbeiten.
                </div>

                <H3>K) EINWILLIGUNG</H3>
                <div>
                  Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter
                  Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen
                  eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der
                  Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.
                </div>
              </SubContainer>

              <H2>2. NAME UND ANSCHRIFT DES FÜR DIE VERARBEITUNG VERANTWORTLICHEN</H2>

              <div>
                Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der
                Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem
                Charakter ist die:
              </div>

              <div>
                <address>
                  <strong>Sotosoft S.L.</strong>
                </address>
                <address>La Reserva S/N, Urb. Senda Chica Bloque 7 P1B</address>
                <address>11310 Sotogrande</address>
                <address>E-Mail: info@sotosoft.app</address>
                <address>Web: https://www.sotosoft.app</address>
              </div>

              <H2>3. COOKIES</H2>

              <div>
                Die Internetseiten der Sotosoft S.L. verwenden Cookies. Cookies sind Textdateien, welche über einen
                Internetbrowser auf einem Computersystem abgelegt und gespeichert werden.
              </div>
              <div>
                Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte
                Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge,
                durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem
                das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern, den
                individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten,
                zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt und
                identifiziert werden.
              </div>
              <div>
                Durch den Einsatz von Cookies kann die Sotosoft S.L. den Nutzern dieser Internetseite
                nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht möglich wären. Mittels
                eines Cookies können die Informationen und Angebote auf unserer Internetseite im Sinne des Benutzers
                optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt, die Benutzer unserer Internetseite
                wiederzuerkennen. Zweck dieser Wiedererkennung ist es, den Nutzern die Verwendung unserer Internetseite
                zu erleichtern. Der Benutzer einer Internetseite, die Cookies verwendet, muss beispielsweise nicht bei
                jedem Besuch der Internetseite erneut seine Zugangsdaten eingeben, weil dies von der Internetseite und
                dem auf dem Computersystem des Benutzers abgelegten Cookie übernommen wird. Ein weiteres Beispiel ist
                das Cookie eines Warenkorbes im Online-Shop. Der Online-Shop merkt sich die Artikel, die ein Kunde in
                den virtuellen Warenkorb gelegt hat, über ein Cookie.
              </div>
              <div>
                Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite jederzeit mittels einer
                entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies
                dauerhaft widersprechen. Ferner können bereits gesetzte Cookies jederzeit über einen Internetbrowser
                oder andere Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern möglich.
                Deaktiviert die betroffene Person die Setzung von Cookies in dem genutzten Internetbrowser, sind unter
                Umständen nicht alle Funktionen unserer Internetseite vollumfänglich nutzbar.
              </div>

              <H2>4. ERFASSUNG VON ALLGEMEINEN DATEN UND INFORMATIONEN</H2>

              <div>
                Die Internetseite der Sotosoft S.L. erfasst mit jedem Aufruf der Internetseite durch eine betroffene
                Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese
                allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden
                können die (1) verwendeten Browsertypen und Versionen, (2) das vom zugreifenden System verwendete
                Betriebssystem, (3) die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite
                gelangt (sogenannte Referrer), (4) die Unterwebseiten, welche über ein zugreifendes System auf unserer
                Internetseite angesteuert werden, (5) das Datum und die Uhrzeit eines Zugriffs auf die Internetseite,
                (6) eine Internet-Protokoll-Adresse (IP-Adresse), (7) der Internet-Service-Provider des zugreifenden
                Systems und (8) sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von Angriffen
                auf unsere informationstechnologischen Systeme dienen.
              </div>
              <div>
                Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die Sotosoft S.L. keine Rückschlüsse
                auf die betroffene Person. Diese Informationen werden vielmehr benötigt, um (1) die Inhalte unserer
                Internetseite korrekt auszuliefern, (2) die Inhalte unserer Internetseite sowie die Werbung für diese zu
                optimieren, (3) die dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und der
                Technik unserer Internetseite zu gewährleisten sowie (4) um Strafverfolgungsbehörden im Falle eines
                Cyberangriffes die zur Strafverfolgung notwendigen Informationen bereitzustellen. Diese anonym erhobenen
                Daten und Informationen werden durch die Sotosoft S.L. daher einerseits statistisch und ferner mit dem
                Ziel ausgewertet, den Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen, um
                letztlich ein optimales Schutzniveau für die von uns verarbeiteten personenbezogenen Daten
                sicherzustellen. Die anonymen Daten der Server-Logfiles werden getrennt von allen durch eine betroffene
                Person angegebenen personenbezogenen Daten gespeichert.
              </div>

              <H2>5. KONTAKTMÖGLICHKEIT ÜBER DIE INTERNETSEITE</H2>

              <div>
                Die Internetseite der Sotosoft S.L. enthält aufgrund von gesetzlichen Vorschriften Angaben, die eine
                schnelle elektronische Kontaktaufnahme zu unserem Unternehmen sowie eine unmittelbare Kommunikation mit
                uns ermöglichen, was ebenfalls eine allgemeine Adresse der sogenannten elektronischen Post
                (E-Mail-Adresse) umfasst. Sofern eine betroffene Person per E-Mail oder über ein Kontaktformular den
                Kontakt mit dem für die Verarbeitung Verantwortlichen aufnimmt, werden die von der betroffenen Person
                übermittelten personenbezogenen Daten automatisch gespeichert. Solche auf freiwilliger Basis von einer
                betroffenen Person an den für die Verarbeitung Verantwortlichen übermittelten personenbezogenen Daten
                werden für Zwecke der Bearbeitung oder der Kontaktaufnahme zur betroffenen Person gespeichert. Es
                erfolgt keine Weitergabe dieser personenbezogenen Daten an Dritte.
              </div>

              <H2>6. ROUTINEMÄSSIGE LÖSCHUNG UND SPERRUNG VON PERSONENBEZOGENEN DATEN</H2>

              <div>
                Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten der
                betroffenen Person nur für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder
                sofern dies durch den Europäischen Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber in
                Gesetzen oder Vorschriften, welchen der für die Verarbeitung Verantwortliche unterliegt, vorgesehen
                wurde.
              </div>
              <div>
                Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und Verordnungsgeber oder
                einem anderen zuständigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden die personenbezogenen
                Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
              </div>

              <H2>
                7. DATENSCHUTZBESTIMMUNGEN ZU EINSATZ UND VERWENDUNG VON GOOGLE ANALYTICS (MIT ANONYMISIERUNGSFUNKTION)
              </H2>
              <div>
                Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite die Komponente Google Analytics
                (mit Anonymisierungsfunktion) integriert. Google Analytics ist ein Web-Analyse-Dienst. Web-Analyse ist
                die Erhebung, Sammlung und Auswertung von Daten über das Verhalten von Besuchern von Internetseiten. Ein
                Web-Analyse-Dienst erfasst unter anderem Daten darüber, von welcher Internetseite eine betroffene Person
                auf eine Internetseite gekommen ist (sogenannte Referrer), auf welche Unterseiten der Internetseite
                zugegriffen oder wie oft und für welche Verweildauer eine Unterseite betrachtet wurde. Eine Web-Analyse
                wird überwiegend zur Optimierung einer Internetseite und zur Kosten-Nutzen-Analyse von Internetwerbung
                eingesetzt.
              </div>
              <div>
                Betreibergesellschaft der Google-Analytics-Komponente ist die Google Ireland Limited, Gordon House,
                Barrow Street, Dublin, D04 E5W5, Ireland. Der für die Verarbeitung Verantwortliche verwendet für die
                Web-Analyse über Google Analytics den Zusatz _gat._anonymizeIp. Mittels dieses Zusatzes wird die
                IP-Adresse des Internetanschlusses der betroffenen Person von Google gekürzt und anonymisiert, wenn der
                Zugriff auf unsere Internetseiten aus einem Mitgliedstaat der Europäischen Union oder aus einem anderen
                Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum erfolgt. Der Zweck der
                Google-Analytics-Komponente ist die Analyse der Besucherströme auf unserer Internetseite. Google nutzt
                die gewonnenen Daten und Informationen unter anderem dazu, die Nutzung unserer Internetseite
                auszuwerten, um für uns Online-Reports, welche die Aktivitäten auf unseren Internetseiten aufzeigen,
                zusammenzustellen, und um weitere mit der Nutzung unserer Internetseite in Verbindung stehende
                Dienstleistungen zu erbringen.
              </div>
              <div>
                Google Analytics setzt ein Cookie auf dem informationstechnologischen System der betroffenen Person. Was
                Cookies sind, wurde oben bereits erläutert. Mit Setzung des Cookies wird Google eine Analyse der
                Benutzung unserer Internetseite ermöglicht. Durch jeden Aufruf einer der Einzelseiten dieser
                Internetseite, die durch den für die Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
                Google-Analytics-Komponente integriert wurde, wird der Internetbrowser auf dem
                informationstechnologischen System der betroffenen Person automatisch durch die jeweilige
                Google-Analytics-Komponente veranlasst, Daten zum Zwecke der Online-Analyse an Google zu übermitteln. Im
                Rahmen dieses technischen Verfahrens erhält Google Kenntnis über personenbezogene Daten, wie der
                IP-Adresse der betroffenen Person, die Google unter anderem dazu dienen, die Herkunft der Besucher und
                Klicks nachzuvollziehen und in der Folge Provisionsabrechnungen zu ermöglichen.
              </div>
              <div>
                Mittels des Cookies werden personenbezogene Informationen, beispielsweise die Zugriffszeit, der Ort, von
                welchem ein Zugriff ausging und die Häufigkeit der Besuche unserer Internetseite durch die betroffene
                Person, gespeichert. Bei jedem Besuch unserer Internetseiten werden diese personenbezogenen Daten,
                einschließlich der IP-Adresse des von der betroffenen Person genutzten Internetanschlusses, an Google in
                den Vereinigten Staaten von Amerika übertragen. Diese personenbezogenen Daten werden durch Google in den
                Vereinigten Staaten von Amerika gespeichert. Google gibt diese über das technische Verfahren erhobenen
                personenbezogenen Daten unter Umständen an Dritte weiter.
              </div>
              <div>
                Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite, wie oben bereits
                dargestellt, jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers
                verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche Einstellung des
                genutzten Internetbrowsers würde auch verhindern, dass Google ein Cookie auf dem
                informationstechnologischen System der betroffenen Person setzt. Zudem kann ein von Google Analytics
                bereits gesetzter Cookie jederzeit über den Internetbrowser oder andere Softwareprogramme gelöscht
                werden.
              </div>
              <div>
                Ferner besteht für die betroffene Person die Möglichkeit, einer Erfassung der durch Google Analytics
                erzeugten, auf eine Nutzung dieser Internetseite bezogenen Daten sowie der Verarbeitung dieser Daten
                durch Google zu widersprechen und eine solche zu verhindern. Hierzu muss die betroffene Person ein
                Browser-Add-On unter dem Link https://tools.google.com/dlpage/gaoptout herunterladen und installieren.
                Dieses Browser-Add-On teilt Google Analytics über JavaScript mit, dass keine Daten und Informationen zu
                den Besuchen von Internetseiten an Google Analytics übermittelt werden dürfen. Die Installation des
                Browser-Add-Ons wird von Google als Widerspruch gewertet. Wird das informationstechnologische System der
                betroffenen Person zu einem späteren Zeitpunkt gelöscht, formatiert oder neu installiert, muss durch die
                betroffene Person eine erneute Installation des Browser-Add-Ons erfolgen, um Google Analytics zu
                deaktivieren. Sofern das Browser-Add-On durch die betroffene Person oder einer anderen Person, die ihrem
                Machtbereich zuzurechnen ist, deinstalliert oder deaktiviert wird, besteht die Möglichkeit der
                Neuinstallation oder der erneuten Aktivierung des Browser-Add-Ons.
              </div>
              <div>
                Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter
                https://www.google.de/intl/de/policies/privacy/ und unter http://www.google.com/analytics/terms/de.html
                abgerufen werden. Google Analytics wird unter diesem Link https://www.google.com/intl/de_de/analytics/
                genauer erläutert.
              </div>

              <H2>8. RECHTSGRUNDLAGE DER VERARBEITUNG</H2>

              <div>
                Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei
                denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen. Ist die Verarbeitung
                personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist,
                erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung
                von Waren oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die
                Verarbeitung auf Art. 6 I lit. b DS-GVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur
                Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zur unseren
                Produkten oder Leistungen. Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche
                eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung
                steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c DS-GVO. In seltenen Fällen
                könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen
                der betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre beispielsweise der
                Fall, wenn ein Besucher in unserem Betrieb verletzt werden würde und daraufhin sein Name, sein Alter,
                seine Krankenkassendaten oder sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder
                sonstige Dritte weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d DS-GVO
                beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO beruhen. Auf dieser
                Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen erfasst
                werden, wenn die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
                Dritten erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht
                überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den
                Europäischen Gesetzgeber besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein
                berechtigtes Interesse anzunehmen sein könnte, wenn die betroffene Person ein Kunde des Verantwortlichen
                ist (Erwägungsgrund 47 Satz 2 DS-GVO).
              </div>

              <H2>
                9. BERECHTIGTE INTERESSEN AN DER VERARBEITUNG, DIE VON DEM VERANTWORTLICHEN ODER EINEM DRITTEN VERFOLGT
                WERDEN
              </H2>

              <div>
                Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DS-GVO ist unser berechtigtes
                Interesse die Durchführung unserer Geschäftstätigkeit zugunsten des Wohlergehens all unserer Mitarbeiter
                und unserer Anteilseigner.
              </div>

              <H2>10. DAUER, FÜR DIE DIE PERSONENBEZOGENEN DATEN GESPEICHERT WERDEN</H2>

              <div>
                Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche
                Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern
                sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind.
              </div>

              <H2>
                11. GESETZLICHE ODER VERTRAGLICHE VORSCHRIFTEN ZUR BEREITSTELLUNG DER PERSONENBEZOGENEN DATEN;
                ERFORDERLICHKEIT FÜR DEN VERTRAGSABSCHLUSS; VERPFLICHTUNG DER BETROFFENEN PERSON, DIE PERSONENBEZOGENEN
                DATEN BEREITZUSTELLEN; MÖGLICHE FOLGEN DER NICHTBEREITSTELLUNG
              </H2>

              <div>
                Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum Teil gesetzlich
                vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus vertraglichen Regelungen (z.B. Angaben
                zum Vertragspartner) ergeben kann. Mitunter kann es zu einem Vertragsschluss erforderlich sein, dass
                eine betroffene Person uns personenbezogene Daten zur Verfügung stellt, die in der Folge durch uns
                verarbeitet werden müssen. Die betroffene Person ist beispielsweise verpflichtet uns personenbezogene
                Daten bereitzustellen, wenn unser Unternehmen mit ihr einen Vertrag abschließt. Eine Nichtbereitstellung
                der personenbezogenen Daten hätte zur Folge, dass der Vertrag mit dem Betroffenen nicht geschlossen
                werden könnte. Vor einer Bereitstellung personenbezogener Daten durch den Betroffenen muss sich der
                Betroffene an einen unserer Mitarbeiter wenden. Unser Mitarbeiter klärt den Betroffenen
                einzelfallbezogen darüber auf, ob die Bereitstellung der personenbezogenen Daten gesetzlich oder
                vertraglich vorgeschrieben oder für den Vertragsabschluss erforderlich ist, ob eine Verpflichtung
                besteht, die personenbezogenen Daten bereitzustellen, und welche Folgen die Nichtbereitstellung der
                personenbezogenen Daten hätte.
              </div>

              <H2>12. BESTEHEN EINER AUTOMATISIERTEN ENTSCHEIDUNGSFINDUNG</H2>

              <div>
                Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische Entscheidungsfindung oder
                ein Profiling. Diese Datenschutzerklärung wurde durch den Datenschutzerklärungs-Generator der DGD
                Deutsche Gesellschaft für Datenschutz GmbH, die als Datenschutzbeauftragter tätig ist.
              </div>
            </>
          </InnerContainer>
        </Col>
      </Row>
    </Container>
  );
}
