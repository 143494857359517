import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Security from '../Modules/Security';
import DefaultLayout from '../Components/Layout/DefaultLayout';
import Dashboard from '../Modules/Dashboard';
import OpenApp from '../Modules/OpenApp';

/**
 * AppRouter()
 * @returns {*}
 * @constructor
 */
export default function AppRouter() {
  const renderRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/security" element={<Security />} />
          <Route path="/install" element={<OpenApp />} />
        </Route>
      </Routes>
    );
  };

  return <BrowserRouter>{renderRoutes()}</BrowserRouter>;
}
