import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${(props) => props.theme.color.primaryColor};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
  text-align: center;
`;

const Inner = styled.div``;

/**
 * DefaultLayout()
 * @constructor
 */
export default function DefaultLayout() {
  return (
    <Container>
      <Inner>
        <Outlet />
      </Inner>
    </Container>
  );
}
