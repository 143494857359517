import { DefaultTheme } from 'styled-components';
import { lighten } from 'polished';

const Theme: DefaultTheme = {
  color: {
    primaryColor: '#2961a8',
    primaryColorLight: lighten(0.08, '#2961a8'),

    text: '#ffffff',
    textLight: '#c0c0c0',

    red: '#e7184e',
    blue: '#00508c',
    green: '#09bd30',
    yellow: '#f4ff0a',
    orange: '#f9b30e',
    purple: '#b200ed',
    blueGray: '#44546a',
  },

  boxShadowDark:
    '-webkit-box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);' +
    '-moz-box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);' +
    'box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);',

  boxShadowDarkHover:
    '-webkit-box-shadow: 0 0 9px 1px rgba(0,0,0,0.4);' +
    '-moz-box-shadow: 0 0 9px 1px rgba(0,0,0,0.4);' +
    'box-shadow: 0 0 9px 1px rgba(0,0,0,0.4);',
};

export default Theme;
