import React from 'react';

/**
 * OpenApp()
 * @constructor
 */
export default function OpenApp() {
  React.useEffect(() => {
    setTimeout(() => open(), 500);
  }, []);

  const open = () => {
    window.location.href = 'https://apps.apple.com/de/app/roblox/id431946152';
  };

  return <></>;
}
